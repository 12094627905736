import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Heading,
  Button,
  VStack,
  Text,
  Flex,
  useColorModeValue,
  Divider,
  useToast,
} from "@chakra-ui/react";
import AppLayout from "./AppLayout";
import axios from "axios";

const TasksDetails = () => {
  const { taskId } = useParams();
  const toast = useToast();

  const [taskDetails, setTaskDetails] = useState({});

  useEffect(() => {
    const getTaskDetails = async () => {
      try {
        const { data } = await axios.get(`/task/details/${taskId}`);
        setTaskDetails(data[0]);
      } catch (error) {}
    };
    getTaskDetails();
  }, [taskId]);

  const acceptTask = async (taskId) => {
    const toastId = "taskDetails";
    try {
      await axios.put(`/task/${taskId}`);
      if (!toast.isActive(toastId)) {
        toast({
          title: "Task Accepted",
          id: toastId,
          status: "success",
          position: "top-right",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch {
      if (!toast.isActive(toastId)) {
        toast({
          title: "Could Not Accept Task",
          status: "error",
          position: "top-right",
          duration: 9000,
          isClosable: true,
          id: toastId,
        });
      }
    }
  };

  const completeTask = async (taskId) => {
    const toastId = "taskDetails";
    try {
      await axios.put(`/task/complete/${taskId}`);
      if (!toast.isActive(toastId)) {
        toast({
          title: "Task Completed",
          id: toastId,
          status: "success",
          position: "top-right",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch {
      if (!toast.isActive(toastId)) {
        toast({
          title: "Could Not Complete Task",
          status: "error",
          position: "top-right",
          duration: 9000,
          isClosable: true,
          id: toastId,
        });
      }
    }
  };
  return (
    <AppLayout>
      <Flex
        rounded={"lg"}
        bg={useColorModeValue("white", "gray.700")}
        boxShadow={"lg"}
        p={8}
        alignItems="center"
        justifyContent="center"
        w={["100%", "md", "lg"]}
      >
        <VStack spacing="8">
          <Heading w="100%" textAlign="center">
            {taskDetails.name}
          </Heading>
          <Divider w="100%" colorScheme="blackAlpha" />
          <Heading size="md" as="h3"></Heading>
          <Heading size="md" as="h3">
            Description
          </Heading>
          <Text>{taskDetails.description}</Text>
          <Text>
            Due: {new Date(+taskDetails.dueDate).toLocaleString("en-us")}
          </Text>
          {taskDetails.status === "new" ? (
            <Button
              colorScheme="blue"
              onClick={() => acceptTask(taskDetails.id)}
            >
              Accept Task
            </Button>
          ) : null}

          {taskDetails.status === "inProgress" ? (
            <Button
              colorScheme="green"
              onClick={() => completeTask(taskDetails.id)}
            >
              Complete Task
            </Button>
          ) : null}
        </VStack>
      </Flex>
    </AppLayout>
  );
};

export default TasksDetails;

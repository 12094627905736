import {
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  Button,
  useDisclosure,
  useColorModeValue,
  Stack,
  Image,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";

import logo from "../images/logo.png";

const NavLink = ({ children, route }) => (
  <Link
    as={RouterLink}
    px={3}
    py={1}
    to={route}
    rounded={"lg"}
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("gray.300", "gray.900"),
    }}
  >
    {children}
  </Link>
);

const NavBar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box bg={useColorModeValue("gray.100", "gray.900")} px={3}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={"center"}>
            <Box>
              <Image
                p="3"
                boxSize="90px"
                objectFit="cover"
                src={logo}
                alt="blended communities"
              />
            </Box>
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
            >
              <NavLink route="/">Home</NavLink>
              <NavLink route="/about">About</NavLink>
            </HStack>
          </HStack>
          <Flex alignItems={"center"}>
            <Button
              as={RouterLink}
              to="/login"
              variant={"solid"}
              colorScheme={"blue"}
              size={"md"}
            >
              Login
            </Button>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box py={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              <NavLink route="/">Home</NavLink>
              <NavLink route="/about">About</NavLink>
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default NavBar;

import { useEffect, useState } from "react";
import axios from "axios";
import AppLayout from "./AppLayout";
import { Link as RouterLink, useHistory } from "react-router-dom";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Link,
  Flex,
  Input,
  Button,
  Alert,
  AlertIcon,
  Text,
} from "@chakra-ui/react";

const Communities = () => {
  const [communities, setCommunities] = useState([]);
  const [search, setSearch] = useState("");
  const [searchError, setSearchError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const getCommunities = async () => {
      try {
        const communities = await axios.get("/community");
        communities.data.map(async (community) => {
          try {
            const details = await axios.get(`/community/details/${community}`);
            setCommunities((prevState) => {
              if (prevState !== details.data) {
                return [...prevState, details.data];
              }
            });
          } catch {}
        });
      } catch (error) {
        return null;
      }
    };
    getCommunities();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (search.length !== 5) {
      return setSearchError(true);
    }

    history.push(`/dashboard/community/search/${search}`);
  };
  return (
    <AppLayout>
      <Flex
        w="100%"
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Heading as="h1" size="xl" textAlign="center" mb="8">
          Your Communities
        </Heading>
        <Button
          size="md"
          colorScheme="teal"
          onClick={() => {
            history.push("/dashboard/communities/add");
          }}
        >
          Create A Community
        </Button>
      </Flex>
      {communities.length > 0 ? (
        <Table variant="simple" style={{ padding: "0px" }} w="full">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th visibility={{ base: "visible", md: "hidden", lg: "visible" }}>
                Description
              </Th>
            </Tr>
          </Thead>
          {communities.map((community) => {
            return (
              <Tbody key={community.id}>
                <Tr>
                  <Td>
                    <Link
                      textDecoration="underline"
                      as={RouterLink}
                      to={`/dashboard/community/details/${community.id}`}
                    >
                      <Text noOfLines={2}>{community.name}</Text>
                    </Link>
                  </Td>
                  <Td
                    visibility={{
                      base: "visible",
                      md: "hidden",
                      lg: "visible",
                    }}
                  >
                    <Text noOfLines={1}>{community.description}</Text>
                  </Td>
                </Tr>
              </Tbody>
            );
          })}
        </Table>
      ) : null}

      <Flex
        flexDirection="column"
        mt="8"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        w="100%"
        h="100%"
      >
        <Heading as="h1" size="xl" textAlign="center" mb="4">
          Find A Community
        </Heading>
        <form onSubmit={handleSubmit}>
          {searchError ? (
            <Alert status="error">
              <AlertIcon />
              Please Enter A Valid Zipcode
            </Alert>
          ) : null}
          <Input
            type="text"
            name="communitySearch"
            placeholder="Zipcode: 32345"
            my="4"
            size="lg"
            w="full"
            onChange={(e) => setSearch(e.target.value)}
          />

          <Button type="submit" size="lg" colorScheme="blue">
            Search
          </Button>
        </form>
      </Flex>
    </AppLayout>
  );
};

export default Communities;

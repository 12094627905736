import {
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useBreakpointValue,
  Container,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
const Home = () => {
  return (
    <Container maxW={"5xl"} height={"100%"}>
      <Stack height={"100%"} direction={{ base: "column", md: "row" }}>
        <Flex flex={2} align={"center"} justify={"center"} h="full">
          <Stack spacing={6} w={"full"} maxW={"lg"}>
            <Heading fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}>
              <Text>Blended</Text>
              <Text
                as={"span"}
                position={"relative"}
                _after={{
                  content: "''",
                  width: "full",
                  height: useBreakpointValue({ base: "20%", md: "30%" }),
                  position: "absolute",
                  bottom: 1,
                  left: 0,
                  bg: "blue.400",
                  zIndex: -1,
                }}
              >
                Communities
              </Text>
              <br />
              <Text color={"blue.400"} as={"span"}>
                Bringing People Together
              </Text>
            </Heading>
            <Text fontSize={{ base: "md", lg: "lg" }} color={"gray.500"}>
              Connect With And Help Members In Your Community
            </Text>
            <Stack direction={{ base: "column", md: "row" }} spacing={4}>
              <Button
                as={RouterLink}
                to="/signup"
                rounded={"full"}
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
              >
                Sign Up
              </Button>
              <Button as={RouterLink} to="/about" rounded={"full"}>
                How It Works
              </Button>
            </Stack>
          </Stack>
        </Flex>
        <Flex flex={{ base: "", md: 3 }}>
          <Image
            display={{ base: "none", md: "block" }}
            alt={"Login Image"}
            objectFit={"cover"}
            src={
              "https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
            }
          />
        </Flex>
      </Stack>
    </Container>
  );
};

export default Home;

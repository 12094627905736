import { useEffect, useState } from "react";
import axios from "axios";
import AppLayout from "./AppLayout";
import { Link as RouterLink, useHistory } from "react-router-dom";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Link,
  Flex,
  Button,
  Text,
} from "@chakra-ui/react";
const Dependents = () => {
  const history = useHistory();
  const [dependents, setDependents] = useState([]);

  const getDependents = async () => {
    try {
      const { data } = await axios.get("/dependent");

      setDependents(data);
    } catch (error) {}
  };

  useEffect(() => {
    getDependents();
  }, []);

  return (
    <AppLayout>
      <Flex
        w="100%"
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Heading as="h1" size="xl" textAlign="center" mb="8">
          Your Dependents
        </Heading>
        <Button
          size="md"
          colorScheme="teal"
          onClick={() => {
            history.push("/dashboard/dependents/add");
          }}
        >
          Add Dependent
        </Button>
      </Flex>
      {dependents.length > 0 ? (
        <Table variant="simple" style={{ padding: "0px" }} w="full">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th visibility={{ base: "visible", md: "hidden", lg: "visible" }}>
                Description
              </Th>
            </Tr>
          </Thead>
          {dependents.map((dependent) => {
            return (
              <Tbody key={dependent.id}>
                <Tr>
                  <Td>
                    <Link
                      textDecoration="underline"
                      as={RouterLink}
                      to={`/dashboard/dependents/edit/${dependent.id}`}
                    >
                      <Text noOfLines={2}>{dependent.name}</Text>
                    </Link>
                  </Td>
                  <Td
                    visibility={{
                      base: "visible",
                      md: "hidden",
                      lg: "visible",
                    }}
                  >
                    <Text noOfLines={1}>{dependent.description}</Text>
                  </Td>
                </Tr>
              </Tbody>
            );
          })}
        </Table>
      ) : (
        ""
      )}
    </AppLayout>
  );
};

export default Dependents;

import { Flex } from "@chakra-ui/react";
// import Footer from "./Footer";
import NavBar from "./Navbar";

const Layout = ({ children }) => {
  return (
    <Flex
      direction="column"
      height="100vh"
      max-width="100vw"
      overflowX="hidden"
    >
      <NavBar />
      {children}
      {/* <Footer /> */}
    </Flex>
  );
};

export default Layout;

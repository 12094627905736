import { Image } from "@chakra-ui/react";
import AppLayout from "./AppLayout";
import dashboardImg from "../../images/dashboard-img.png";
const Dashboard = () => {
  return (
    <AppLayout>
      <Image objectFit="cover" src={dashboardImg} alt="blended communities" />
    </AppLayout>
  );
};

export default Dashboard;

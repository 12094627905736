import { Flex, Heading, Spinner } from "@chakra-ui/react";
const LoadingSpinner = () => {
  return (
    <Flex
      bg="blue.500"
      height="100vh"
      align="center"
      justify="center"
      direction="column"
    >
      <Spinner
        thickness="6px"
        speed="1.3s"
        emptyColor="blue.300"
        color="white"
        size="xl"
        w="32"
        h="32"
        mb="4"
      />
      <Heading color="white">Loading</Heading>
    </Flex>
  );
};

export default LoadingSpinner;

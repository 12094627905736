import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { IoPeople, IoHeart } from "react-icons/io5";

const Feature = ({ text, icon, iconBg, desc }) => {
  return (
    <Stack direction={"column"} align={"center"}>
      <Flex
        w={20}
        h={20}
        my={"4"}
        align={"center"}
        justify={"center"}
        rounded={"full"}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Heading as="h3" size="lg" mb={"4"}>
        {text}
      </Heading>
      <span>{desc}</span>
    </Stack>
  );
};

const About = () => {
  return (
    <Container maxW={"5xl"} py={12} height={"100%"}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} mb="4">
        <Stack spacing={6} mb="12">
          <Text
            textTransform={"uppercase"}
            color={"blue.400"}
            fontWeight={600}
            fontSize={"sm"}
            bg={useColorModeValue("blue.50", "blue.900")}
            p={2}
            alignSelf={"flex-start"}
            rounded={"md"}
          >
            Our Mission
          </Text>
          <Heading>To rebuild communities one network at a time</Heading>
          <Text color={"gray.500"} fontSize={"lg"}></Text>
          <Stack
            spacing={4}
            mb="4"
            divider={
              <StackDivider
                borderColor={useColorModeValue("gray.100", "gray.700")}
              />
            }
          >
            <Feature
              icon={<Icon as={IoPeople} color={"yellow.500"} w={12} h={12} />}
              iconBg={useColorModeValue("yellow.100", "yellow.900")}
              text={"Build Your Communities"}
              desc={
                "To aid in closing the economic gap in America and support people who do not have resources, our company wanted to create ways to find trusted child/elderly care. We are striving to work together as a commmunity to give working folks a work-life balance and feel good about helping each other within their trusted community. So take a look at the future!"
              }
            />
            <Feature
              icon={<Icon as={IoHeart} color={"red.500"} w={12} h={12} />}
              iconBg={useColorModeValue("red.100", "red.900")}
              text={"Help Your Loved Ones"}
              desc={
                "We are a company dedicated to helping people with the obstacles of childcare, elder care and even our fur babies through kindness, compassion and paying it forward. We developed an app that would help barter services between networks and pay it forward to ensure connections between people in rural to metropolitan areas."
              }
            />
          </Stack>
        </Stack>
        <Flex>
          <Image
            display={{ base: "none", md: "block" }}
            rounded={"md"}
            alt={"feature image"}
            src={
              "https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
            }
            objectFit={"cover"}
          />
        </Flex>
      </SimpleGrid>
    </Container>
  );
};

export default About;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import AppLayout from "./AppLayout";
import {
  Heading,
  VStack,
  Text,
  Flex,
  Divider,
  useColorModeValue,
} from "@chakra-ui/react";

const DependentDetails = () => {
  const { dependentId } = useParams();
  const [dependent, setDependent] = useState({});

  useEffect(() => {
    const getDependentInfo = async () => {
      try {
        const { data } = await axios.get(`/dependent/${dependentId}`);
        setDependent(data);
      } catch (error) {}
    };
    getDependentInfo();
  }, [dependentId]);

  return (
    <AppLayout>
      <Flex
        rounded={"lg"}
        bg={useColorModeValue("white", "gray.700")}
        boxShadow={"lg"}
        p={8}
        alignItems="center"
        justifyContent="center"
        w={["100%", "md", "lg"]}
      >
        <VStack spacing="6">
          <Heading w="100%" textAlign="center">
            {dependent.name}
          </Heading>
          <Divider w="100%" colorScheme="blackAlpha" />
          <Heading size="md" as="h3">
            Age: {dependent.age}
          </Heading>
          <Heading size="md" as="h3">
            Description
          </Heading>
          <Text>{dependent.description}</Text>
          <Text>
            Added: {new Date(dependent.createdAt).toLocaleString("en-us")}
          </Text>
          {/* {dependent.status === "new" ? (
            <Button colorScheme="blue" onClick={() => acceptTask(dependent.id)}>
              Accept Task
            </Button>
          ) : null}

          {dependent.status === "inProgress" ? (
            <Button
              colorScheme="green"
              onClick={() => completeTask(dependent.id)}
            >
              Complete Task
            </Button>
          ) : null} */}
        </VStack>
      </Flex>
    </AppLayout>
  );
};

export default DependentDetails;

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Link,
  Button,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppLayout from "./AppLayout";
import { Link as RouterLink } from "react-router-dom";

const CommunitySearch = () => {
  const { zipCode } = useParams();
  const [communities, setCommunities] = useState([{}]);

  const toast = useToast();

  useEffect(() => {
    const getCommunityByZip = async () => {
      try {
        const { data } = await axios.get(`/community/${zipCode}`);
        setCommunities(data);
      } catch (error) {}
    };

    getCommunityByZip();
  }, [zipCode]);

  const joinCommunity = async (communityId) => {
    try {
      await axios.put(`/community/join/${communityId}`);
      toast({
        title: "Community Joined",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Could Not Join Community",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  return (
    <AppLayout>
      <Heading mb="4">Search Results For {zipCode}</Heading>
      {communities.length > 0 ? (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Description</Th>
              <Th isNumeric></Th>
            </Tr>
          </Thead>
          <Tbody>
            {communities.map((community) => (
              <Tr key={community.id}>
                <Td>
                  <Link
                    textDecoration="underline"
                    as={RouterLink}
                    to={`/dashboard/community/details/${community.id}`}
                  >
                    {community.name}
                  </Link>
                </Td>
                <Td>{community.description}</Td>
                <Td isNumeric>
                  <Button
                    colorScheme="teal"
                    onClick={() => joinCommunity(community.id)}
                  >
                    Join
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <p>No results</p>
      )}
    </AppLayout>
  );
};

export default CommunitySearch;

import { useState } from "react";
import AppLayout from "./AppLayout";
import { useHistory } from "react-router";
import { useForm, Controller } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
  Flex,
  Heading,
  Button,
  Textarea,
  FormHelperText,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";

import axios from "axios";

const AddDependent = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState({});
  const { control, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await axios.post("/dependent", { ...data });
      setLoading(false);
      history.push("/dashboard/dependents");
    } catch (error) {
      setSubmitError(error.response.data);
      setLoading(false);
    }
  };

  return (
    <AppLayout>
      <Heading mb="8">Add A Dependent</Heading>
      {submitError.message ? (
        <Alert status="error" w="80" mb="8">
          <AlertIcon />
          <AlertDescription>{submitError.message}</AlertDescription>
        </Alert>
      ) : null}

      <Flex
        rounded={"lg"}
        bg={useColorModeValue("white", "gray.700")}
        boxShadow={"lg"}
        p={8}
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        w={["100%", "md", "lg"]}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isRequired>
            <FormLabel>Dependent Name</FormLabel>
            <Controller
              name="name"
              control={control}
              defaultValue={""}
              rules={{ required: true, minLength: 5, maxLength: 64 }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  size="lg"
                  w="80"
                  placeholder="Dependent Name"
                  mb="4"
                />
              )}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Dependent Age</FormLabel>
            <Controller
              name="age"
              control={control}
              defaultValue={""}
              rules={{ required: true, minLength: 2, maxLength: 2 }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  size="lg"
                  w="80"
                  placeholder="Dependent Age"
                  mb="4"
                />
              )}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Dependent Description</FormLabel>
            <Controller
              name="description"
              control={control}
              defaultValue={""}
              rules={{ required: true, minLength: 6, maxLength: 280 }}
              render={({ field }) => (
                <Textarea
                  {...field}
                  size="lg"
                  w="80"
                  placeholder="Description"
                  rows="4"
                />
              )}
            />
            <FormHelperText mb="4" textAlign="left">
              Character Limit: 280
            </FormHelperText>
          </FormControl>
          <Button
            type="submit"
            size="lg"
            colorScheme="blue"
            isLoading={loading}
          >
            Add Dependent
          </Button>
        </form>
      </Flex>
    </AppLayout>
  );
};

export default AddDependent;

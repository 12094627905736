import { useState, useEffect } from "react";
import AppLayout from "./AppLayout";
import { useHistory, useParams } from "react-router";
import { useForm, Controller } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
  Flex,
  Heading,
  Button,
  Textarea,
  FormHelperText,
  Alert,
  AlertIcon,
  AlertDescription,
  Select,
} from "@chakra-ui/react";

import axios from "axios";

const AddTask = () => {
  const history = useHistory();
  const { communityId } = useParams();
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState({});
  const [dependent, setDependent] = useState([]);
  const [date, setDate] = useState(new Date(Date.now()).setSeconds(0));
  const { control, handleSubmit } = useForm();

  useEffect(() => {
    const getDependents = async () => {
      const { data } = await axios.get("/dependent");
      setDependent(data);
    };
    getDependents();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    console.log(data);
    try {
      await axios.post("/task", { communityId, dueDate: date, ...data });
      setLoading(false);
      history.push("/dashboard/tasks");
    } catch (error) {
      setSubmitError(error.response.data);
      setLoading(false);
    }
  };

  return (
    <AppLayout>
      {console.log(
        new Date(date - new Date(Date.now()).getTimezoneOffset() * 60000)
          .toISOString()
          .replace(".000Z", "Z")
      )}
      <Heading mb="8">Add A Task</Heading>
      {submitError.message ? (
        <Alert status="error" w="80" mb="8">
          <AlertIcon />
          <AlertDescription>{submitError.message}</AlertDescription>
        </Alert>
      ) : null}

      <Flex
        rounded={"lg"}
        bg={useColorModeValue("white", "gray.700")}
        boxShadow={"lg"}
        p={8}
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        w={["100%", "md", "lg"]}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isRequired>
            <FormLabel>Task Name</FormLabel>
            <Controller
              name="name"
              control={control}
              defaultValue={""}
              rules={{ required: true, minLength: 5, maxLength: 64 }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  size="lg"
                  w="80"
                  placeholder="Task Name"
                  mb="4"
                />
              )}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Task Description</FormLabel>
            <Controller
              name="description"
              control={control}
              defaultValue={""}
              rules={{ required: true, minLength: 6, maxLength: 280 }}
              render={({ field }) => (
                <Textarea
                  {...field}
                  size="lg"
                  w="80"
                  placeholder="Description"
                  rows="4"
                />
              )}
            />
            <FormHelperText mb="4" textAlign="left">
              Character Limit: 280
            </FormHelperText>
          </FormControl>
          <FormControl>
            <FormLabel>Dependent</FormLabel>
            <Controller
              name="dependentId"
              control={control}
              render={({ field }) => (
                <Select variant="outline" placeholder=" " mb="4" {...field}>
                  {dependent.map((dependent) => {
                    return (
                      <option key={dependent.id} value={dependent.id}>
                        {dependent.name}
                      </option>
                    );
                  })}
                </Select>
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Due Date</FormLabel>
            <Input
              type="datetime-local"
              w="full"
              mb="4"
              isRequired
              onChange={(e) => setDate(Date.parse(e.target.value))}
              value={
                new Date(
                  date - new Date(Date.now()).getTimezoneOffset() * 60000
                )
                  .toISOString()
                  .split(".")[0]
              }
              min={
                new Date(
                  date - new Date(Date.now()).getTimezoneOffset() * 60000
                )
                  .toISOString()

                  .split(".")[0]
              }
              max={new Date(date + 2629800000).toISOString().split(".")[0]}
            />
          </FormControl>

          <Button
            type="submit"
            size="lg"
            colorScheme="blue"
            isLoading={loading}
          >
            Add Task
          </Button>
        </form>
      </Flex>
    </AppLayout>
  );
};

export default AddTask;

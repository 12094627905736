import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ProtectedRoute from "./components/ProtectedRoute";

import About from "./components/About";
import Home from "./components/Home";
import Layout from "./components/Layout";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Dashboard from "./components/dashboard/Dashboard";
import Communities from "./components/dashboard/Communities";
import Tasks from "./components/dashboard/Tasks";
import CommunityDetails from "./components/dashboard/CommunityDetails";
import AddCommunity from "./components/dashboard/AddCommunity";
import CommunitySearch from "./components/dashboard/CommunitySearch";
import TasksDetails from "./components/dashboard/TasksDetails";
import AddTask from "./components/dashboard/AddTask";
import Dependents from "./components/dashboard/Dependents";
import AddDependent from "./components/dashboard/AddDependent";
import DependentDetails from "./components/dashboard/DependentDetails";
const App = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <ProtectedRoute exact path="/dashboard" component={Dashboard} />
          <ProtectedRoute
            exact
            path="/dashboard/communities"
            component={Communities}
          />
          <ProtectedRoute
            exact
            path="/dashboard/communities/add"
            component={AddCommunity}
          />
          <ProtectedRoute
            exact
            path="/dashboard/community/details/:communityId"
            component={CommunityDetails}
          />
          <ProtectedRoute
            exact
            path="/dashboard/community/search/:zipCode"
            component={CommunitySearch}
          />
          <ProtectedRoute exact path="/dashboard/tasks" component={Tasks} />
          <ProtectedRoute
            exact
            path="/dashboard/tasks/details/:taskId"
            component={TasksDetails}
          />
          <ProtectedRoute
            exact
            path="/dashboard/tasks/add/:communityId"
            component={AddTask}
          />
          <ProtectedRoute
            exact
            path="/dashboard/dependents"
            component={Dependents}
          />
          <ProtectedRoute
            exact
            path="/dashboard/dependents/add"
            component={AddDependent}
          />
          <ProtectedRoute
            exact
            path="/dashboard/dependents/edit/:dependentId"
            component={DependentDetails}
          />
          <Layout>
            <Route exact path="/about">
              <About />
            </Route>

            <Route exact path="/login">
              <Login />
            </Route>

            <Route exact path="/signup">
              <Signup />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
          </Layout>
        </Switch>
      </Router>
    </div>
  );
};

export default App;

import { useEffect, useState } from "react";
import {
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import AppLayout from "./AppLayout";
import axios from "axios";

const Tasks = () => {
  const [currentTasks, setCurrentTasks] = useState([]);
  const [previousTasks, setPreviousTasks] = useState([]);
  useEffect(() => {
    const getTasks = async () => {
      try {
        const { data } = await axios.get("/task");
        setCurrentTasks(data.filter((task) => task.status === "inProgress"));
        setPreviousTasks(data.filter((task) => task.status === "complete"));
      } catch (error) {}
    };
    getTasks();
  }, []);
  return (
    <AppLayout>
      {currentTasks.length > 0 ? (
        <>
          <Heading textAlign="left" w="100%">
            Current Tasks
          </Heading>
          <Table variant="simple" mt="4" mb="4">
            <Thead>
              <Tr>
                <Th>Task</Th>
                <Th>Description</Th>
                <Th isNumeric>Due Date</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentTasks.map((task) => (
                <Tr key={task.id}>
                  <Td>
                    {" "}
                    <Link
                      textDecoration="underline"
                      as={RouterLink}
                      to={`/dashboard/tasks/details/${task.id}`}
                    >
                      {task.name}
                    </Link>
                  </Td>

                  <Td>{task.description}</Td>
                  <Td isNumeric>
                    {new Date(+task.dueDate).toLocaleDateString('en-us')}
                  
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      ) : (
        ""
      )}
      {previousTasks.length > 0 ? (
        <>
          <Heading textAlign="left" w="100%">
            Previous Tasks
          </Heading>
          <Table variant="simple" mt="4">
            <Thead>
              <Tr>
                <Th>Task</Th>
                <Th
                  visibility={{ base: "hidden", md: "visible", lg: "visible" }}
                >
                  Description
                </Th>
                <Th isNumeric>Due Date</Th>
              </Tr>
            </Thead>
            <Tbody>
              {previousTasks.map((task) => (
                <Tr key={task.id}>
                  <Td>
                    {" "}
                    <Link
                      textDecoration="underline"
                      as={RouterLink}
                      to={`/dashboard/tasks/details/${task.id}`}
                    >
                      <Text noOfLines={2}>{task.name}</Text>
                    </Link>
                  </Td>

                  <Td
                    visibility={{
                      base: "hidden",
                      md: "visible",
                      lg: "visible",
                    }}
                  >
                    <Text noOfLines={1}>{task.description}</Text>
                  </Td>
                  <Td isNumeric>
                    {new Date(task.createdAt).toLocaleDateString("en-us")}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      ) : (
        ""
      )}

      {previousTasks.length === 0 && currentTasks.length === 0 ? (
        <Heading as="h3" size="lg" mt="8">
          No Tasks
        </Heading>
      ) : (
        ""
      )}
    </AppLayout>
  );
};

export default Tasks;

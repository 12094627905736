import { useEffect, useState } from "react";
import { useParams, Link as RouterLink, useHistory } from "react-router-dom";
import axios from "axios";
import AppLayout from "./AppLayout";
import {
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Flex,
  Link,
  Button,
} from "@chakra-ui/react";

const CommunityDetails = () => {
  const { communityId } = useParams();
  const [communityInfo, setCommunityInfo] = useState({});
  const [communityTasks, setCommunityTasks] = useState([]);

  const history = useHistory();

  useEffect(() => {
    const getCommunityInfo = async () => {
      try {
        const community = await axios.get(`/community/details/${communityId}`);
        setCommunityInfo(community.data);

        const tasks = await axios.get(`/task/community/details/${communityId}`);
        setCommunityTasks(tasks.data);
      } catch (error) {}
    };
    getCommunityInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityId]);

  return (
    <AppLayout>
      <Flex
        direction="row"
        textAlign="center"
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        mb="8"
      >
        <Flex direction="column" alignItems="flex-start">
          <Heading as="h1" size="xl">
            {communityInfo.name}
          </Heading>
          <Text>{communityInfo.description}</Text>
          <Text>
            Community Since:{" "}
            {new Date(communityInfo.createdAt).toLocaleDateString("en-us")}{" "}
          </Text>
        </Flex>
        <Button
          colorScheme="blue"
          onClick={() => history.push(`/dashboard/tasks/add/${communityId}`)}
        >
          Add A Task
        </Button>
      </Flex>
      {communityTasks.length > 0 ? (
        <Table variant="simple" mt="4">
          <Thead>
            <Tr>
              <Th>Task</Th>
              <Th>Description</Th>
              <Th isNumeric>Due Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {communityTasks.map((task) => (
              <Tr key={task.id}>
                <Td>
                  {" "}
                  <Link
                    textDecoration="underline"
                    as={RouterLink}
                    to={`/dashboard/tasks/details/${task.id}`}
                  >
                    {task.name}
                  </Link>
                </Td>

                <Td>{task.description}</Td>
                <Td isNumeric>
                  {new Date(+task.dueDate).toLocaleDateString("en-us")}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Heading as="h3" size="lg" mt="8">
          No Tasks
        </Heading>
      )}
    </AppLayout>
  );
};

export default CommunityDetails;

import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  Alert,
  AlertIcon,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoginError(false);
      setSubmitLoading(true);
      await axios.post("/auth/login", {
        email,
        password,
      });
      setSubmitLoading(true);
      history.replace("/dashboard");
    } catch (error) {
      setLoginError(error.response.status);
      setSubmitLoading(false);
    }
  };
  return (
    <Flex
      height={"100%"}
      direction="column"
      alignItems={"center"}
      justifyContent={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} maxW={"lg"} py={4} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Log In</Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            to enjoy all of our cool{" "}
            <Link as={RouterLink} to="/about" color={"blue.400"}>
              features
            </Link>{" "}
            ✌️
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4} w={"72"}>
            <form onSubmit={handleSubmit}>
              <FormControl id="email">
                <FormLabel>Email address</FormLabel>
                <Input
                  type="email"
                  autoComplete="on"
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  required
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align={"start"}
                  justify={"space-between"}
                >
                  <Checkbox>Remember me</Checkbox>
                  <Link as={RouterLink} to="/signup" color={"blue.400"}>
                    Not A Member?
                  </Link>
                </Stack>
                {!submitLoading ? (
                  <Button
                    bg={"blue.400"}
                    color={"white"}
                    _hover={{
                      bg: "blue.500",
                    }}
                    size="lg"
                    type="submit"
                  >
                    Log In
                  </Button>
                ) : (
                  <Button
                    bg={"blue.400"}
                    color={"white"}
                    _hover={{
                      bg: "blue.500",
                    }}
                    variant="outline"
                    spinnerPlacement="end"
                    isLoading
                    loadingText="Loggin You In"
                  >
                    Log In
                  </Button>
                )}
              </Stack>
            </form>
            {loginError ? (
              <Alert status="error">
                <AlertIcon />
                {loginError === 400
                  ? "Incorrect Email or Password"
                  : "There was an error processing your request"}
              </Alert>
            ) : null}
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Login;

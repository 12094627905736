import { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const checkAuth = async () => {
    try {
      await axios.get("/auth/current");
      setLoggedIn(true);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);
  if (loggedIn) {
    return (
      <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
    );
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <>
        <h1>Not Authorized</h1>
      </>
    );
  }
};

export default ProtectedRoute;
